var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "esims-main-container"
  }, [!_vm.isCoBranded ? _c('section-hero-base', {
    scopedSlots: _vm._u([{
      key: "hero-title",
      fn: function () {
        return [_c('hero', {
          staticClass: "tw-relative",
          attrs: {
            "title": _vm.$t('scenes.homepage.head-title'),
            "description": _vm.$t('scenes.homepage.head-description', {
              count: _vm.$t('scenes.homepage.head-description-count')
            }),
            "seo-title": _vm.generatedSeoTitle
          }
        })];
      },
      proxy: true
    }, {
      key: "country-search",
      fn: function () {
        return [_c('country-search', {
          ref: "countrySearch",
          attrs: {
            "list-type": _vm.listType
          },
          on: {
            "countrySearchClearAfter": _vm.onCountrySearchClearAfter
          }
        })];
      },
      proxy: true
    }, {
      key: "tabs",
      fn: function () {
        return [_c('store-tabs', {
          attrs: {
            "tabs": _vm.tabs,
            "prop-list-type": _vm.listType
          }
        })];
      },
      proxy: true
    }], null, false, 3071588924)
  }) : _c('section-hero-cobranded', {
    scopedSlots: _vm._u([{
      key: "hero-title",
      fn: function () {
        return [_c('cobranding-hero-title', {
          staticClass: "tw-relative",
          attrs: {
            "title": _vm.coBrandedHeroTitle,
            "seo-title": _vm.generatedSeoTitle
          }
        })];
      },
      proxy: true
    }, {
      key: "country-search",
      fn: function () {
        return [_c('country-search', {
          ref: "countrySearch",
          staticClass: "tw-w-full tw-ml-0 sm:tw-w-[500px] sm:!tw-mb-16",
          attrs: {
            "list-type": _vm.listType
          },
          on: {
            "countrySearchClearAfter": _vm.onCountrySearchClearAfter
          }
        })];
      },
      proxy: true
    }, {
      key: "tabs",
      fn: function () {
        return [_c('store-tabs', {
          attrs: {
            "tabs": _vm.tabs,
            "prop-list-type": _vm.listType
          }
        })];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c('div', [!_vm.isCoBranded ? _c('HomepageBanners') : _vm._e(), _vm._v(" "), _c('client-only', [_vm.shouldDisplayCheckoutRestrictedByUserLocationPopup ? [_c('div', {
    staticClass: "new-container mx-auto px-20 mt-20"
  }, [_c('UaeRestrictionModal', {
    attrs: {
      "modal-view": _vm.shouldDisplayCheckoutRestrictedByUserLocationPopup
    },
    on: {
      "closeUaeRestrictionModal": _vm.onCloseRestrictionModal
    }
  })], 1)] : _vm._e()], 2), _vm._v(" "), _c('div', {
    staticClass: "store-container"
  }, [_c('b-container', {
    staticClass: "px-20 px-sm-30",
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "airgsm-container mx-auto"
  }, [_c('div', {
    class: ['store-list pb-20 pb-sm-30 pb-md-40 pt-20', _vm.listType === 'global' && _vm.packages_isHaveDVT ? 'pt-sm-20 pt-md-20' : 'pt-sm-30 pt-md-40']
  }, [_vm.listType !== 'global' ? _c('h2', {
    class: ['typo-h1 mb-20', {
      'd-flex justify-content-center align-items-center gap-20': _vm.listType === 'local-packages' || _vm.listType === 'regional-packages',
      'text-center': _vm.listType !== 'local'
    }, {
      'mb-sm-20': _vm.packages_isHaveDVT
    }, {
      'mb-sm-40': !_vm.packages_isHaveDVT
    }],
    attrs: {
      "id": "store-title",
      "data-testid": "store-title"
    }
  }, [(_vm.listType === 'local-packages' || _vm.listType === 'regional-packages') && _vm.getPageTitleImage ? [_c('img', {
    directives: [{
      name: "lazy-load",
      rawName: "v-lazy-load"
    }],
    staticClass: "shadowed-small",
    attrs: {
      "src": "/assets/images/placeholders/homepage-sim-flag.png",
      "data-src": _vm.getPageTitleImage,
      "alt": _vm.getPageTitle,
      "width": 37,
      "height": 28
    }
  })] : _vm._e(), _vm._v("\n\n                " + _vm._s(_vm.getPageTitle) + "\n              ")], 2) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "store-countries-and-regions-list d-grid gap-10 gap-sm-30",
    class: {
      'store-grid-cols-1 store-grid-cols-sm-2 store-grid-cols-md-3 store-grid-cols-lg-4': _vm.listType === 'local' || _vm.listType === 'local-packages' && _vm.list && _vm.list.length > 1 && !_vm.isSearch,
      'store-grid-cols-1 store-grid-cols-sm-2': _vm.listType === 'regional' || _vm.listType === 'regional-packages',
      'grid-cols-1': _vm.listType === 'local-packages' && _vm.list && _vm.list.length === 1 || _vm.listType === 'local-packages' && _vm.list && _vm.list.length > 1 && _vm.isSearch
    }
  }, [_c('client-only', [_vm.listIsLoading && (_vm.listType === 'local' || _vm.listType === 'regional') ? [_vm._l(_vm.listSkeletonCount, function (item, index) {
    return _c('skeleton-item', {
      key: `list-item-skeleton-${index}`
    });
  })] : _vm._e()], 2), _vm._v(" "), !_vm.listIsLoading && _vm.list && _vm.list.length > 0 && (_vm.listType === 'local' || _vm.listType === 'regional') ? _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      key: `list-item-${index}`,
      staticClass: "store-item aloo",
      attrs: {
        "id": _vm.getElementID(_vm.listType, item.id)
      }
    }, [_c('store-item', {
      attrs: {
        "item": item,
        "item-index": index,
        "list-type": _vm.listType,
        "show-data-voice-text": _vm.packages_isHaveDVT
      },
      on: {
        "onChangePlainType": _vm.onChangePlainTypeFromStoreItem
      }
    })], 1);
  }) : _vm._e(), _vm._v(" "), _c('div', {
    ref: "storePackagesList",
    staticClass: "store-packages-list",
    class: {
      'store-col-span-1 store-col-span-sm-2 store-col-span-md-3 store-col-span-lg-4': _vm.listType === 'local-packages' && _vm.list && _vm.list.length > 1,
      'store-col-span-1': _vm.listType === 'regional-packages' || _vm.listType === 'global' || _vm.listType === 'local-packages' && _vm.list && _vm.list.length === 1,
      'store-col-span-1 store-col-span-sm-2': _vm.listType === 'regional-packages',
      'd-none store-col-span-1 store-col-span-sm-2 store-col-span-md-3 store-col-span-lg-4': _vm.listType === 'local',
      'd-none store-col-span-1': _vm.listType === 'regional',
      'regional-ssr-h0': _vm.regionalSsrHeightZero
    }
  }, [_vm.packages_isLoading && _vm.packages_skeletonCount > 0 ? [_c('client-only', [_c('div', {
    staticClass: "d-grid gap-30 store-grid-cols-1 store-grid-cols-sm-2 store-grid-cols-md-3"
  }, [_vm._l(_vm.packages_skeletonCount, function (item, index) {
    return _c('packages-skeleton-item', {
      key: `list-item-skeleton-${index}`
    });
  })], 2)])] : _vm._e(), _vm._v(" "), _vm.listType === 'global' || _vm.listType === 'local-packages' || _vm.listType === 'regional-packages' ? [!_vm.packages_isLoading && _vm.isReadyForPackages ? [_vm.packages_isHaveDVT ? [_c('DataTypeTabs', {
    class: ['mt-0', {
      'mt-sm-15': _vm.listType === 'global'
    }, {
      'mt-sm-5': _vm.listType !== 'global'
    }],
    attrs: {
      "selectedDataType": _vm.selectedDataPlanType,
      "type": "horizontal"
    },
    on: {
      "onChangePlainType": _vm.onChangePlainTypeFromStoreItem
    }
  })] : _vm._e(), _vm._v(" "), _c('nuxt-child', {
    attrs: {
      "loading": _vm.packages_isLoading,
      "list-fade": _vm.packages_startShowDVTChange,
      "list": _vm.packages_list,
      "list-type": _vm.listType,
      "selected-country": _vm.selectedCountry,
      "selected-data-type": _vm.selectedDataPlanType
    },
    on: {
      "onPackageDetailMounted": _vm.onPackageDetailMounted
    }
  })] : _vm._e()] : _vm._e()], 2)], 2), _vm._v(" "), !_vm.listIsLoading && _vm.listType === 'local' && _vm.list && _vm.list.length > 0 ? [_c('div', {
    staticClass: "show-all-btn-wrapper"
  }, [_c('button', {
    staticClass: "show-all-btn btn-primary-hv",
    attrs: {
      "data-testid": "show-all-countries-button",
      "disabled": _vm.isLoadingAllCountries
    },
    on: {
      "click": function ($event) {
        return _vm.showCountries(!_vm.isAllCountriesShown);
      }
    }
  }, [_vm.isLoadingAllCountries ? [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  })] : [!_vm.isAllCountriesShown ? [_c('span', [_vm._v(_vm._s(_vm.$t('components.esims.view-all')))])] : [_c('span', [_vm._v(_vm._s(_vm.$t('components.esims.view-popular')))])]]], 2)])] : _vm._e(), _vm._v(" "), !_vm.listIsLoading && _vm.listType === 'local-packages' ? [_c('div', {
    staticClass: "show-all-btn-wrapper"
  }, [_c('nuxt-link', {
    staticClass: "show-all-btn btn-primary-hv",
    attrs: {
      "to": _vm.localePath('/')
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('components.esims.view-all')))])])], 1)] : _vm._e(), _vm._v(" "), !_vm.listIsLoading && _vm.listType === 'regional-packages' ? [_c('div', {
    staticClass: "show-all-btn-wrapper"
  }, [_c('nuxt-link', {
    staticClass: "show-all-btn btn-primary-hv",
    attrs: {
      "to": _vm.localePath('/regional-esim')
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('components.esims.show-all-regions')))])])], 1)] : _vm._e()], 2)])])], 1), _vm._v(" "), _c('client-only', [_vm.signupRewardModal.showComp ? [_c(_vm.signupRewardModal.showCompData.is, _vm._g(_vm._b({
    tag: "component",
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('button', {
          staticClass: "call-to-action dark mb-2",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.goToEsimDetails(_vm.esimRedeemedOnSignup.id);
            }
          }
        }, [_vm._v("\n                " + _vm._s(_vm.$t('components.esim.earn.view-esim-button')) + "\n              ")]), _vm._v(" "), _c('button', {
          staticClass: "call-to-action",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.hideVoucherRewardModal.apply(null, arguments);
            }
          }
        }, [_vm._v("\n                " + _vm._s(_vm.$t('global.close')) + "\n              ")])];
      },
      proxy: true
    }], null, false, 2806869709)
  }, 'component', _vm.signupRewardModal.showCompData.props, false), _vm.signupRewardModal.showCompData.on))] : _vm._e(), _vm._v(" "), _vm.fistLoginRewardModal.showComp ? [_c(_vm.fistLoginRewardModal.showCompData.is, _vm._g(_vm._b({
    tag: "component",
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('button', {
          staticClass: "call-to-action dark",
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.firstModalShown.apply(null, arguments);
            }
          }
        }, [_vm._v("\n                " + _vm._s(_vm.$t('components.money.earn.airmoney-earned-button')) + "\n              ")])];
      },
      proxy: true
    }], null, false, 360538380)
  }, 'component', _vm.fistLoginRewardModal.showCompData.props, false), _vm.fistLoginRewardModal.showCompData.on))] : _vm._e()], 2)], 1), _vm._v(" "), _c('HowDoesAiraloWork', {
    key: `HowDoesAiraloWork-${_vm.crKey}`
  }), _vm._v(" "), !_vm.isCoBranded ? _c('WhyUseAiralo', {
    key: `WhyUseAiralo-${_vm.crKey}`,
    attrs: {
      "data": _vm.whyAiraloReviews
    }
  }) : _vm._e(), _vm._v(" "), _c('client-only', [!_vm.isCoBranded ? _c('CustomerReviews', {
    key: `CustomerReviews-${_vm.crKey}`,
    attrs: {
      "data": _vm.customerReviews
    }
  }) : _vm._e()], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }